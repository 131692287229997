/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: lieu-webapp-contrib
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**

 À défaut d'utiliser un Promise dans les règles de l'art, ce petit utilitaire permet de faire un foreach dont l'appel à chaque itération suivante doit se faire après avoir effectué une opération asynchrone.

 Exemple d'utilisation simple :

 import { ForEach } from './ForEach.js';

 const urls = ['https://api.site.com/resource', 'https://api.site.com/object', 'https://api.site.com/structure'];

 ForEach.async(urls, (url, index, next) => {
    fetch(url)
    .then(r => r.json)
    .then(r => {
        // do something with r.
        next();
    });
}, () => {
    console.log('finished!');
})

 */

window.ForEach = function() {
    return {
        next() {
            this.index++;
            if (this.index === this.arr.length) {
                this.finish();
            } else {
                var item = this.arr[this.index];
                var self = this;
                this.iter.apply(this.iter, [item, this.index, function() {
                    self.next.apply(self);
                }]);
            }
        }
    };
};

window.ForEach.async = function(arr, iter, finish) {
    var instance = new ForEach();
    instance.arr = arr;
    instance.iter = iter;
    instance.finish = finish;
    instance.index = -1;
    instance.next();
};
