/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: lieu-webapp-contrib
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* global ForEach */
(function () {
    'use strict';
    var EMPLACEMENTS = {
        MARKER: 'MARKER',
        POLYGONE: 'POLYGONE'
    };

    function getCoordinatesFromAddress(geocoder, emplacement, callback) {
        if (!!emplacement && emplacement.address !== null) {
            var params = {};
            if (geocoder.key !== '') {
                params.params = {
                    key: geocoder.key
                };
            }
            var geocoderProvider = new window.GeoSearch[geocoder.provider + 'Provider'](params);
            geocoderProvider.search({
                query: emplacement.address
            }).then(function (results) {
                if (results.length > 0) {
                    var r = results[0];
                    callback({
                        latitude: r.y,
                        longitude: r.x
                    });
                } else {
                    callback({
                        latitude: 0,
                        longitude: 0
                    });
                }
            });
        } else {
            callback({
                latitude: 0,
                longitude: 0
            });
        }
    }

    var storedEmplacements = {};
    window.addEventListener('load', function () {
        [].forEach.call(document.querySelectorAll('[data-map=leaflet]'), function (wrapper) {
            var emplacements = JSON.parse(wrapper.getAttribute('data-map-emplacements')) || [];
            var provider = wrapper.getAttribute('data-map-provider');
            var geocoder = {
                provider: wrapper.getAttribute('data-map-geocoder'),
                key: wrapper.getAttribute('data-map-geocoder-key')
            };
            emplacements = emplacements.filter(function (emplacement) {
                return emplacement.coordinates !== null && emplacement.coordinates.length > 0 || emplacement.address !== null;
            });
            ForEach.async(emplacements, function (emplacement, index, next) {
                if (!!emplacement && emplacement.coordinates !== null && emplacement.coordinates.length > 0) {
                    next();
                } else {
                    getCoordinatesFromAddress(geocoder, emplacement, function (coordinates) {
                        emplacement.coordinates = coordinates;
                        next();
                    });
                }
            }, function () {
                if (emplacements.length) {
                    var firstEmplacement = emplacements[0];
                    var center = [0, 0];
                    if (firstEmplacement.type === EMPLACEMENTS.MARKER) {
                        center = firstEmplacement.coordinates;
                    } else if (firstEmplacement.type === EMPLACEMENTS.POLYGONE) {
                        center = firstEmplacement.coordinates[0];
                    } else {
                        console.warn('Type d\'emplacement non géré');
                    }
                    var map = L.map(wrapper.querySelector('.map-canvas'), {
                        center: center,
                        zoom: 14
                    });
                    L.tileLayer.provider(provider).addTo(map);
                    emplacements.forEach(function (emplacement) {
                        var em;
                        if (emplacement.type === EMPLACEMENTS.MARKER) {
                            em = L.marker(emplacement.coordinates);
                        } else if (emplacement.type === EMPLACEMENTS.POLYGONE) {
                            em = L.polygon(emplacement.coordinates);
                        } else {
                            console.warn('Type d\'emplacement non géré');
                        }
                        if (em) {
                            var a = '';
                            var aElm = document.querySelector('[data-emplacement-uniqid="' + emplacement.uniqId + '"] .route-link');
                            if (aElm) {
                                a = aElm.outerHTML;
                            }
                            em
                                .bindPopup(
                                    '<address>' +
                                    '<header>' +
                                    '<h1>' + emplacement.popupHeader + '</h1>' +
                                    '</header>' +
                                    '<div class="content">' + emplacement.popupBody + '</div>' +
                                    '<footer>' + a + '</footer>' +
                                    '</address>'
                                )
                                .addTo(map);
                            storedEmplacements[emplacement.uniqId] = em;
                            if (emplacements.length === 1) {
                                em.openPopup();
                            }
                            em.addEventListener('click', function () {
                                em.openPopup();
                            });
                        }
                    });
                }
                [].forEach.call(document.querySelectorAll('footer dl dt, footer dl dd > span'), function (item) {
                    item.style.display = 'none';
                });
                [].forEach.call(document.querySelectorAll('[data-emplacement-uniqid]'), function (item) {
                    var id = item.getAttribute('data-emplacement-uniqid');
                    var emplacement = storedEmplacements[id];
                    if (!!emplacement) {
                        item.addEventListener('click', function () {
                            emplacement.openPopup();
                        });
                    }
                });
            });
        });
    });
})();
